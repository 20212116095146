import { ClickAwayListener, Tooltip } from "@mui/material";
import React from "react";
import { ReactElement, useState } from "react";

const ClickableTooltip = ({
    title,
    children,
}: {
    title: string;
    children: ReactElement<any, any>;
}) => {
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div style={{ textAlign: "left" }}>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={title}
                >
                    {React.cloneElement(children, {
                        onClick: handleTooltipOpen,
                    })}
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

export default ClickableTooltip;
