import { getFirestore, collection, addDoc } from "firebase/firestore";
import { LogCategories } from "src/enums/loggerEnums";
import { PaymentLoggerType } from "src/types/loggerTypes";
import { DateTime } from "luxon";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

async function log(collectionName: LogCategories, data: any) {
    try {
        const timestamp = DateTime.now().toString();
        const payload = {
            ...data,
            timestamp,
        };
        await addDoc(collection(firestore, collectionName), payload);
    } catch (error) {
        console.log("Logging error", error);
    }
}

export async function paymentLog(data: PaymentLoggerType) {
    log(LogCategories.PAYMENT, data);
}

export async function paymentErrorLog(data: any) {
    log(LogCategories.PAYMENT, data);
}
