import {
    Box,
    IconButton,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import { tableHead } from "../table/tableStyles";
import { OrderTypes } from "src/enums/sortingsEnum";
import TableSortIcon from "../table/TableSortIcon";
import { AllBidsHeadRowType, dataType } from "src/types/allBidsPageTypes";
import { BsInfoCircle } from "react-icons/bs";
import ClickableTooltip from "../common/ClickableTooltip";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFAFA",
        fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const AllBidsHeadRow = ({
    order,
    orderBy,
    setOrderProps,
    headRow,
}: AllBidsHeadRowType) => {
    const setOrder = (order: OrderTypes) => {
        setOrderProps((orderProps) => ({ ...orderProps, order }));
    };

    const setOrderBy = (orderBy: dataType) => {
        setOrderProps((orderProps) => ({ ...orderProps, orderBy }));
    };

    const sortHandler = (id: dataType) => {
        if (orderBy !== id) {
            setOrder(OrderTypes.ASC);
        } else {
            switch (order) {
                case "asc":
                    setOrder(OrderTypes.DESC);
                    break;
                default:
                    setOrder(OrderTypes.ASC);
                    break;
            }
        }
        setOrderBy(id);
    };

    return (
        <TableHead>
            <TableRow
                sx={{
                    "& .MuiTableCell-head": {
                        position: "relative",
                    },
                }}
            >
                {headRow.map((field, index) => (
                    <StyledTableCell
                        size="small"
                        key={field.id}
                        align={field.align ?? "center"}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: field.align,
                                alignItems: "center",
                            }}
                        >
                            {field.sorting ? (
                                <TableSortLabel
                                    onClick={() => sortHandler(field.id)}
                                    hideSortIcon
                                    sx={tableHead.headStyle}
                                >
                                    <Typography sx={tableHead.headStyle}>
                                        {field.label}
                                    </Typography>
                                    <TableSortIcon
                                        type={order}
                                        active={orderBy === field.id}
                                    />
                                </TableSortLabel>
                            ) : (
                                <Typography sx={tableHead.headStyle}>
                                    {field.label}
                                </Typography>
                            )}
                            {field.info ? (
                                <ClickableTooltip title={field.info}>
                                    <IconButton>
                                        <BsInfoCircle
                                            style={tableHead.headStyle}
                                        />
                                    </IconButton>
                                </ClickableTooltip>
                            ) : null}
                        </Box>
                        {!(index === headRow.length - 1) && (
                            <Box sx={tableHead.divider}></Box>
                        )}
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default AllBidsHeadRow;
