import { Chip, styled, Tooltip } from "@mui/material";
import {
    ProgressStatusBgColors,
    ProgressStatusTextColors,
    UserStatusEnum,
} from "src/enums/allBidsPageEnums";

const chipContentMap = {
    [UserStatusEnum.SUCCESS]: {
        text: "Success",
        textColor: ProgressStatusTextColors.SUCCESS,
        bgColor: ProgressStatusBgColors.SUCCESS,
    },
    [UserStatusEnum.PENDING]: {
        text: "Pending",
        textColor: ProgressStatusTextColors.PENDING,
        bgColor: ProgressStatusBgColors.PENDING,
    },
    [UserStatusEnum.FAILURE]: {
        text: "Failure",
        textColor: ProgressStatusTextColors.FAILURE,
        bgColor: ProgressStatusBgColors.FAILURE,
    },
    [UserStatusEnum.ARCHIVED]: {
        text: "Archived",
        textColor: ProgressStatusTextColors.ARCHIVED,
        bgColor: ProgressStatusBgColors.ARCHIVED,
    },
    [UserStatusEnum.PAYMENT]: {
        text: "Payment Pending",
        textColor: ProgressStatusTextColors.PAYMENT,
        bgColor: ProgressStatusBgColors.PAYMENT,
    },
    [UserStatusEnum.SUBMISSIONPEND]: {
        text: "Submission Pending",
        textColor: ProgressStatusTextColors.SUBPEND,
        bgColor: ProgressStatusBgColors.SUBPEND,
    },
    [UserStatusEnum.AUCTION_LOST]: {
        text: "Auction Lost",
        textColor: ProgressStatusTextColors.AUCTION_LOST,
        bgColor: ProgressStatusBgColors.AUCTION_LOST,
    },
    [UserStatusEnum.WAITING]: {
        text: "In Progress",
        textColor: ProgressStatusTextColors.PENDING,
        bgColor: ProgressStatusBgColors.PENDING,
    },
    [UserStatusEnum.INPROGRESS]: {
        text: "In Progress",
        textColor: ProgressStatusTextColors.PENDING,
        bgColor: ProgressStatusBgColors.PENDING,
    },
    [UserStatusEnum.RESULT_PENDING]: {
        text: "Result Pending",
        textColor: ProgressStatusTextColors.RESULT_PENDING,
        bgColor: ProgressStatusBgColors.RESULT_PENDING,
    },
};

const StyledChip = styled(Chip)(() => ({
    fontSize: "12px",
    borderRadius: 5,
    height: "25px",
}));

const ProgressStatusChip = ({
    chipType,
    label,
    onClick,
    tooltip,
}: {
    chipType: UserStatusEnum;
    label?: string;
    onClick?: () => void;
    tooltip?: string;
}) => {
    const chipContent = chipContentMap[chipType];
    const baseSx = {
        color: chipContent.textColor,
        bgcolor: chipContent.bgColor,
    };

    // clickable - computed
    const isClickable = onClick !== undefined;
    const sx = isClickable
        ? {
              ...baseSx,
              border: `1px solid ${chipContent.textColor}`,
              "&:hover": {
                  backgroundColor: "#E7F6E1",
              },
          }
        : baseSx;

    let chipComponent = (
        <StyledChip
            clickable={isClickable}
            onClick={onClick}
            label={label ?? chipContent.text}
            sx={sx}
        />
    );

    if (tooltip !== undefined) {
        chipComponent = <Tooltip title={tooltip}>{chipComponent}</Tooltip>;
    }

    return chipContent ? chipComponent : null;
};

export default ProgressStatusChip;
