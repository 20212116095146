export const bulletStyle = {
    fontSize: "8px",
    color: "#FFB700",
    marginTop: "8px",
};

export const TypographyContentStyle = {
    fontSize: "16px",
    lineHeight: "16px",
    color: "#615d67",
    "& > *": {
        whiteSpace: "normal !important",
    },
};

export const TypographyHeaderStyle = {
    fontSize: "25px",
    fontWeight: 600,
    color: "#FFB700",
    marginBottom: 1.5,
};

export const NotificationBoxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 1,
    bgcolor: "white",
    color: "black",
    padding: 3,
    borderRadius: 3,
    position: "relative",
    rowGap: 0,
};

export const closeIconStyle = {
    position: "absolute",
    top: 15,
    right: 15,
    cursor: "pointer",
    color: "#615d67",
};
