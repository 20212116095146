import { Fragment, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import {
    headerStyle,
    formLabelStyle,
    signInFormContainer,
    formTextInputStyle,
    formSubmitButtonStyle,
} from "./Form.styles";
import useAuth from "src/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { defaultRoute } from "src/context/CurrentRouteContext";
import useMessage from "src/hooks/useMessage";
import SpinnerLoader from "src/components/loader/SpinnerLoader";
import PasswordField from "src/components/input/PasswordTextField";
import { getAuthPageContainerStyle } from "src/utils/helperFunctions";

function SignInForm() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { showSuccess, showError } = useMessage();

    // Sign In Page General State

    const initialMessage = location.state?.message;
    const containerStyle = useMemo(() => getAuthPageContainerStyle(window), []);

    const [loginLoading, setLoginLoading] = useState(false);
    const [authorized, setAuthorized] = useState(true);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        window.history.replaceState({}, document.title);
        if (initialMessage) {
            timeout = setTimeout(() => {
                showSuccess(initialMessage);
            }, 500);
        }
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (auth.isLogged || auth.user) {
            const path = localStorage.getItem("currentRoute") || defaultRoute;
            setAuthorized(true);
            navigate(path);
        } else {
            setAuthorized(false);
        }
    }, [auth]);

    const validationSchema = yup.object({
        username: yup.string().required("Username is required"),
        password: yup.string().required("Password is required"),
    });

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema,
        async onSubmit(values) {
            setLoginLoading(true);
            try {
                await auth.login(values.username, values.password);
                navigate("/dashboard/preBids");
            } catch (e: any) {
                if (e.response?.status === 401) {
                    showError("Username or password is incorrect");
                } else if (e.response?.status === 500) {
                    showError("Internal server error");
                } else if (e.message.includes("disabled")) {
                    showError(
                        `Your account has been disabled. Please contact ${process.env.REACT_APP_DEFAULT_USER_EMAIL}`,
                    );
                } else if (e.message.includes("notUser")) {
                    showError("User not authorized");
                } else {
                    showError("User login failed");
                }
            } finally {
                setLoginLoading(false);
            }
        },
    });

    return !authorized ? (
        <Fragment>
            <Grid
                zIndex={0}
                container
                sx={containerStyle}
                alignContent={"left"}
            >
                <Grid item xs={12} md={6} lg={4.5} sx={signInFormContainer}>
                    <Typography sx={headerStyle}>SIGN IN</Typography>
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <InputLabel htmlFor="username" sx={formLabelStyle}>
                            Username
                        </InputLabel>
                        <TextField
                            fullWidth
                            id="username"
                            name="username"
                            autoComplete="off"
                            value={formik.values.username}
                            sx={formTextInputStyle}
                            onChange={(event) => {
                                const lowerCaseValue =
                                    event.target.value.trim();
                                formik.setFieldValue(
                                    "username",
                                    lowerCaseValue,
                                );
                            }}
                            error={
                                formik.touched.username &&
                                Boolean(formik.errors.username)
                            }
                            helperText={
                                formik.touched.username &&
                                formik.errors.username
                            }
                        />
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent="space-between"
                        >
                            <InputLabel htmlFor="password" sx={formLabelStyle}>
                                Password
                            </InputLabel>
                            <Typography
                                sx={{ ...formLabelStyle, cursor: "pointer" }}
                                onClick={() => navigate("/forgotPassword")}
                            >
                                {"Forgot password?"}
                            </Typography>
                        </Box>
                        <PasswordField
                            form={formik}
                            sx={formTextInputStyle}
                            formValueName="password"
                        />
                        <Button
                            type="submit"
                            sx={formSubmitButtonStyle}
                            disabled={loginLoading}
                            endIcon={
                                <SpinnerLoader
                                    loading={loginLoading}
                                    size={20}
                                    sx={{ marginLeft: "10px" }}
                                />
                            }
                        >
                            Sign In
                        </Button>
                    </form>
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        marginTop={"20px"}
                    >
                        <Typography
                            sx={{ ...formLabelStyle, marginRight: "10px" }}
                        >
                            Don&apos;t have an account?
                        </Typography>
                        <Typography
                            sx={{
                                ...formLabelStyle,
                                cursor: "pointer",
                                color: "#EFD016",
                            }}
                            onClick={() => navigate("/register")}
                        >
                            Sign Up
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    ) : null;
}

export default SignInForm;
