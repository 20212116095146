import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Dispatch, SetStateAction } from "react";
import TextInputWrapper from "src/components/registrantProfile/ProfileTextInputWrapper";
import { InputSectionType } from "src/types/registrantProfileTypes";
import {
    emailRegex,
    InputSectionTag,
    zipRegex,
} from "src/utils/registrantProfileData";
import { regProfileStyles } from "./registrantProfileStyles";
import { getValue, onlyNumbers, trimSpaces } from "src/utils/helperFunctions";

const InputSection = ({
    data,
    setData,
    errors,
    setErrors,
    errorSwitch,
    tag,
    disabled = false,
}: {
    data: InputSectionType;
    setData: Dispatch<SetStateAction<InputSectionType>>;
    errors: InputSectionType;
    errorSwitch: any;
    setErrors: Dispatch<SetStateAction<InputSectionType>>;
    tag: InputSectionTag;
    disabled?: boolean;
}) => {
    return (
        <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            paddingTop={2}
            paddingBottom={2}
        >
            <Grid md={4} sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.name !== ""}
                    label="Name"
                    warning={errors.name}
                >
                    <TextField
                        // id="name"
                        name="name"
                        type="text"
                        onChange={(e) => {
                            const value = getValue(e.target.value);
                            setData((data) => ({
                                ...data,
                                name: value,
                            }));
                            if (value === "")
                                setErrors((errors) => ({
                                    ...errors,
                                    name: "Required Field",
                                }));
                            else {
                                setErrors((errors) => ({
                                    ...errors,
                                    name: "",
                                }));
                            }
                        }}
                        value={data.name}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.company !== ""}
                    label="Company"
                    warning={errors.company}
                >
                    <TextField
                        // id="company"
                        name="company"
                        onChange={(e) => {
                            const value = getValue(e.target.value);
                            setData((data) => ({
                                ...data,
                                company: value,
                            }));
                            setErrors((errors) => ({
                                ...errors,
                                company: "",
                            }));
                        }}
                        value={data.company}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.street !== ""}
                    label="Street"
                    warning={errors.street}
                >
                    <TextField
                        // id="street"
                        name="street"
                        onChange={(e) => {
                            const value = getValue(e.target.value);
                            setData((data) => ({
                                ...data,
                                street: value,
                            }));
                            if (value === "")
                                setErrors((errors) => ({
                                    ...errors,
                                    street: "Required Field",
                                }));
                            else
                                setErrors((errors) => ({
                                    ...errors,
                                    street: "",
                                }));
                        }}
                        value={data.street}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.city !== ""}
                    label="City"
                    warning={errors.city}
                >
                    <TextField
                        // id="city"
                        name="city"
                        onChange={(e) => {
                            const value = getValue(e.target.value);
                            setData((data) => ({
                                ...data,
                                city: value,
                            }));
                            if (value === "")
                                setErrors((errors) => ({
                                    ...errors,
                                    city: "Required Field",
                                }));
                            else
                                setErrors((errors) => ({
                                    ...errors,
                                    city: "",
                                }));
                        }}
                        value={data.city}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.state !== ""}
                    label="State"
                    warning={errors.state}
                >
                    <TextField
                        // id="state"
                        name="state"
                        value={data.state}
                        onChange={(e) => {
                            const value = getValue(e.target.value);
                            setData((data) => ({
                                ...data,
                                state: value,
                            }));
                            if (value === "")
                                setErrors((errors) => ({
                                    ...errors,
                                    state: "Required Field",
                                }));
                            else
                                setErrors((errors) => ({
                                    ...errors,
                                    state: "",
                                }));
                        }}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.country !== ""}
                    label="Country"
                    warning={errors.country}
                >
                    <TextField
                        // id="country"
                        name="country"
                        onChange={(e) => {
                            const value = getValue(e.target.value);
                            setData((data) => ({
                                ...data,
                                country: value,
                            }));
                            if (value === "")
                                setErrors((errors) => ({
                                    ...errors,
                                    country: "Required Field",
                                }));
                            else
                                setErrors((errors) => ({
                                    ...errors,
                                    country: "",
                                }));
                        }}
                        value={data.country}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.zip !== ""}
                    label="Zip"
                    warning={errors.zip}
                >
                    <TextField
                        // id="zip"
                        name="zip"
                        onChange={(e) => {
                            const value = trimSpaces(
                                onlyNumbers(getValue(e.target.value)),
                            );
                            setData((data) => ({
                                ...data,
                                zip: value,
                            }));
                            if (value === "")
                                setErrors((errors) => ({
                                    ...errors,
                                    zip: "Required Field",
                                }));
                            else {
                                if (!zipRegex.test(value))
                                    setErrors((errors) => ({
                                        ...errors,
                                        zip: "Invalid Zip",
                                    }));
                                else
                                    setErrors((errors) => ({
                                        ...errors,
                                        zip: "",
                                    }));
                            }
                        }}
                        value={data.zip}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.phone !== ""}
                    label="Phone"
                    warning={errors.phone}
                >
                    <PhoneInput
                        className="phoneInput"
                        value={data.phone}
                        numberInputProps={{
                            style: {
                                padding: "12px",
                                borderRadius: "15px",
                                borderColor: "#3A354180",
                                borderWidth: "1px",
                                backgroundColor: "transparent",
                                fontFamily: "'Inter', sans-serif",
                                fontSize: "15px",
                            },
                        }}
                        onChange={(e: any) => {
                            const value = e;
                            try {
                                setData((data) => ({
                                    ...data,
                                    phone: value,
                                }));
                                if (value === "")
                                    setErrors((errors) => ({
                                        ...errors,
                                        phone: "Required Field",
                                    }));
                                else {
                                    if (!isValidPhoneNumber(value))
                                        setErrors((errors) => ({
                                            ...errors,
                                            phone: "Invalid Phone",
                                        }));
                                    else
                                        setErrors((errors) => ({
                                            ...errors,
                                            phone: "",
                                        }));
                                }
                            } catch (error) {
                                setData((data) => ({
                                    ...data,
                                    phone: "",
                                }));
                                setErrors((errors) => ({
                                    ...errors,
                                    phone: "Required Field",
                                }));
                            }
                        }}
                        disabled={disabled}
                    />
                </TextInputWrapper>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                <TextInputWrapper
                    hasWarning={errorSwitch[tag] && errors.email !== ""}
                    label="Email"
                    warning={errors.email}
                >
                    <TextField
                        // id="email"
                        name="email"
                        onChange={(e) => {
                            const value = trimSpaces(getValue(e.target.value));
                            setData((data) => ({
                                ...data,
                                email: value,
                            }));
                            if (value === "")
                                setErrors((errors) => ({
                                    ...errors,
                                    email: "Required Field",
                                }));
                            else {
                                if (!emailRegex.test(value))
                                    setErrors((errors) => ({
                                        ...errors,
                                        email: "Invalid Email",
                                    }));
                                else
                                    setErrors((errors) => ({
                                        ...errors,
                                        email: "",
                                    }));
                            }
                        }}
                        value={data.email}
                        sx={regProfileStyles.textField}
                        inputProps={{
                            style: {
                                margin: 0,
                                padding: "10px 8px",
                            },
                        }}
                        disabled={
                            disabled || tag === InputSectionTag.OWNER_INFO
                        }
                    />
                </TextInputWrapper>
            </Grid>
        </Grid>
    );
};

export default InputSection;
