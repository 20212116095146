import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InputSection from "src/components/registrantProfile/InputSection";
import InputSectionDomain from "src/components/registrantProfile/InputSectionDomain";
import {
    regProfileStyles,
    checkboxContainerStyle,
} from "src/components/registrantProfile/registrantProfileStyles";
import useData from "src/hooks/useApi";
import useAuth from "src/hooks/useAuth";
import {
    ErrorSwitchType,
    FormDataTypeSubmit,
    InputSectionType,
    NameserverInputSectionType,
} from "src/types/registrantProfileTypes";
import {
    domainsValidationSchema,
    InputDomainTag,
    inputErrors,
    inputs,
    InputSectionTag,
    validationSchema,
} from "src/utils/registrantProfileData";
import PageLoader from "../components/common/PageLoader";
import useMessage from "../hooks/useMessage";
import jwtDecode from "jwt-decode";
import { getRefreshToken } from "../utils/jwtFunctions";
import {
    mainContentContainer,
    pageHeaderStyle,
} from "src/components/SharedStyles";
import CheckBoxWithLabel from "src/components/common/CheckBox";
import HeaderWithSeparator from "src/components/common/HeaderWithSeperator";
import { useNavigate } from "react-router-dom";

const RegistrantProfilePageForm = ({
    update = false,
}: {
    update?: boolean;
}) => {
    const { postData, fetchData } = useData();
    const navigate = useNavigate();
    const auth = useAuth();
    const initErrors = update ? inputs : inputErrors;
    const [loading, setLoading] = useState(true);
    const { showSuccess, showError } = useMessage();

    const [ownerInfo, setOwnerInfo] = useState<InputSectionType>({
        ...inputs,
    });
    const [adminInfo, setAdminInfo] = useState<InputSectionType>({
        ...inputs,
    });
    const [technicalInfo, setTechnicalInfo] = useState<InputSectionType>({
        ...inputs,
    });
    const [billingInfo, setBillingInfo] = useState<InputSectionType>({
        ...inputs,
    });
    const [nameServerInfo, setNameServerInfo] =
        useState<NameserverInputSectionType>({
            nameserver1: "",
            nameserver2: "",
        });

    const [ownerErrors, setOwnerErrors] = useState<InputSectionType>({
        ...initErrors,
    });
    const [adminErrors, setAdminErrors] = useState<InputSectionType>({
        ...initErrors,
    });
    const [technicalErrors, setTechnicalErrors] = useState<InputSectionType>({
        ...initErrors,
    });
    const [billingErrors, setBillingErrors] = useState<InputSectionType>({
        ...initErrors,
    });
    const [nameServerErrors, setNameServerErrors] =
        useState<NameserverInputSectionType>({
            nameserver1: update ? "" : "Required Field",
            nameserver2: update ? "" : "Required Field",
        });

    const [errorSwitch, setErrorSwitch] = useState<ErrorSwitchType>({
        ownerInfo: false,
        adminInfo: false,
        technicalInfo: false,
        billingInfo: false,
        nameservers: false,
    });

    const [isOwnerAdmin, setIsOwnerAdmin] = useState<boolean>(true);
    const [isOwnerTechnical, setIsOwnerTechnical] = useState<boolean>(true);
    const [isOwnerBilling, setIsOwnerBilling] = useState<boolean>(true);

    // Loading State for BE update
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);

    async function getData() {
        if (update) {
            try {
                setLoading(true);

                const response: any = await fetchData(
                    "/users/getRegistrantProfileDetails",
                );
                if (response) {
                    console.log(response);
                    setOwnerInfo(response.ownerInfo);
                    if (response?.adminInfo) {
                        setIsOwnerAdmin(
                            response.adminInfo?.sameAsOwner ? true : false,
                        );
                        setAdminInfo(response.adminInfo);
                    }
                    if (response?.technicalContact) {
                        setIsOwnerTechnical(
                            response.technicalContact?.sameAsOwner
                                ? true
                                : false,
                        );
                        setTechnicalInfo(response.technicalContact);
                    }
                    if (response?.billingContact) {
                        setIsOwnerBilling(
                            response.billingContact?.sameAsOwner ? true : false,
                        );
                        setBillingInfo(response.billingContact);
                    }
                    setNameServerInfo(response.nameserverSettings);
                } else {
                    setOwnerErrors({ ...inputErrors });
                    setAdminErrors({ ...inputErrors });
                    setTechnicalErrors({ ...inputErrors });
                    setBillingErrors({ ...inputErrors });
                    setNameServerErrors({
                        nameserver1: "Required Field",
                        nameserver2: "Required Field",
                    });
                    showError("Please complete your profile");
                    if (auth.user?.username) {
                        await getRefreshToken(auth.user.username);
                        const token: any = sessionStorage.getItem("authToken");
                        const decoded: any = jwtDecode(token);
                        if (decoded)
                            auth.switchIsCompleted(decoded.isCompleted);
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }
        } else {
            try {
                setLoading(true);
                setOwnerInfo((emptyOwnerInfo) => ({
                    ...emptyOwnerInfo,
                    email: auth.user?.email ?? "",
                }));
                setOwnerErrors((emptyOwnerError) => ({
                    ...emptyOwnerError,
                    email: "",
                }));
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }
        }
    }

    const submitForm = async () => {
        try {
            setIsUpdating(true);
            const formData: FormDataTypeSubmit = {
                ownerInfo: update
                    ? ownerInfo
                    : { ...inputs, email: auth.user?.email ?? "" },
                adminInfo: update
                    ? { ...adminInfo, sameAsOwner: isOwnerAdmin }
                    : { ...inputs, sameAsOwner: isOwnerAdmin },
                technicalContact: update
                    ? { ...technicalInfo, sameAsOwner: isOwnerTechnical }
                    : { ...inputs, sameAsOwner: isOwnerTechnical },
                billingContact: update
                    ? { ...billingInfo, sameAsOwner: isOwnerBilling }
                    : { ...inputs, sameAsOwner: isOwnerBilling },
                nameserverSettings: {
                    nameserver1: update ? nameServerInfo.nameserver1 : "",
                    nameserver2: update ? nameServerInfo.nameserver2 : "",
                },
            };

            setErrorSwitch((errorSwitch) => ({
                ...errorSwitch,
                ownerInfo: true,
                adminInfo: !isOwnerAdmin ? true : false,
                technicalInfo: !isOwnerTechnical ? true : false,
                billingInfo: !isOwnerBilling ? true : false,
                nameservers: true,
            }));

            validationSchema.validateSync(ownerInfo);
            formData.ownerInfo = ownerInfo;
            domainsValidationSchema.validateSync(nameServerInfo);
            formData.nameserverSettings = nameServerInfo;
            if (nameServerInfo.nameserver1 === nameServerInfo.nameserver2) {
                throw new Error("Duplicate entry");
            }

            if (!isOwnerAdmin) {
                validationSchema.validateSync(adminInfo);
                formData.adminInfo = {
                    ...adminInfo,
                    sameAsOwner: false,
                };
            } else {
                formData.adminInfo = { ...ownerInfo, sameAsOwner: true };
            }

            if (!isOwnerTechnical) {
                validationSchema.validateSync(technicalInfo);
                formData.technicalContact = {
                    ...technicalInfo,
                    sameAsOwner: false,
                };
            } else {
                formData.technicalContact = { ...ownerInfo, sameAsOwner: true };
            }

            if (!isOwnerBilling) {
                validationSchema.validateSync(billingInfo);
                formData.billingContact = {
                    ...billingInfo,
                    sameAsOwner: false,
                };
            } else {
                formData.billingContact = { ...ownerInfo, sameAsOwner: true };
            }

            const result: any = await postData(
                "/users/createRegistrantProfile",
                formData,
            );
            if (result.success) {
                setIsUpdateSuccess(true);
            } else {
                throw new Error("Failed to update profile data");
            }
        } catch (error) {
            console.log(error);
            showError("Failed to update profile data");
            setIsUpdating(false);
        }
    };

    useEffect(() => {
        getData();
    }, [auth.user]);

    useEffect(() => {
        if (isUpdateSuccess) {
            showSuccess("Successfully updated profile data");
            if (!update) {
                sessionStorage.setItem("isCompleted", "true");
                auth.switchIsCompleted(true);
            }
            setTimeout(() => {
                setIsUpdating(false);
                navigate("/dashboard/preBids");
            }, 1200);
        }
    }, [isUpdateSuccess]);

    return (
        <Box alignItems="center" sx={mainContentContainer}>
            {loading && update ? (
                <PageLoader />
            ) : (
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={pageHeaderStyle} align={"left"}>
                            Registrant Profile Settings
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <HeaderWithSeparator
                            label="Owner Info"
                            labelStyle={regProfileStyles.sectionHeading}
                        />
                        <InputSection
                            tag={InputSectionTag.OWNER_INFO}
                            data={ownerInfo}
                            setData={setOwnerInfo}
                            errors={ownerErrors}
                            setErrors={setOwnerErrors}
                            errorSwitch={errorSwitch}
                            disabled={isUpdating}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <HeaderWithSeparator
                            label="Admin Info"
                            labelStyle={regProfileStyles.sectionHeading}
                        />
                        <CheckBoxWithLabel
                            label={"Same as owner"}
                            isChecked={isOwnerAdmin}
                            setIsChecked={setIsOwnerAdmin}
                            disabled={isUpdating}
                            sx={checkboxContainerStyle}
                        />
                        {!isOwnerAdmin && (
                            <InputSection
                                tag={InputSectionTag.ADMIN_INFO}
                                data={adminInfo}
                                setData={setAdminInfo}
                                errors={adminErrors}
                                setErrors={setAdminErrors}
                                errorSwitch={errorSwitch}
                                disabled={isUpdating}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <HeaderWithSeparator
                            label="Technical Info"
                            labelStyle={regProfileStyles.sectionHeading}
                        />
                        <CheckBoxWithLabel
                            label={"Same as owner"}
                            isChecked={isOwnerTechnical}
                            setIsChecked={setIsOwnerTechnical}
                            disabled={isUpdating}
                            sx={checkboxContainerStyle}
                        />
                        {!isOwnerTechnical && (
                            <InputSection
                                tag={InputSectionTag.TECHNICAL_INFO}
                                data={technicalInfo}
                                setData={setTechnicalInfo}
                                errors={technicalErrors}
                                setErrors={setTechnicalErrors}
                                errorSwitch={errorSwitch}
                                disabled={isUpdating}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <HeaderWithSeparator
                            label="Billing Contact"
                            labelStyle={regProfileStyles.sectionHeading}
                        />
                        <CheckBoxWithLabel
                            label={"Same as owner"}
                            isChecked={isOwnerBilling}
                            setIsChecked={setIsOwnerBilling}
                            disabled={isUpdating}
                            sx={checkboxContainerStyle}
                        />
                        {!isOwnerBilling && (
                            <InputSection
                                tag={InputSectionTag.BILLING_INFO}
                                data={billingInfo}
                                setData={setBillingInfo}
                                errors={billingErrors}
                                setErrors={setBillingErrors}
                                errorSwitch={errorSwitch}
                                disabled={isUpdating}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <HeaderWithSeparator
                            label="Nameserver Settings"
                            labelStyle={regProfileStyles.sectionHeading}
                        />
                        <InputSectionDomain
                            tag={InputDomainTag.NAMESERVER}
                            data={nameServerInfo}
                            setData={setNameServerInfo}
                            errors={nameServerErrors}
                            setErrors={setNameServerErrors}
                            errorSwitch={errorSwitch}
                            disabled={isUpdating}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"flex-end"}
                    >
                        <Button
                            sx={regProfileStyles.buttonStyle}
                            onClick={submitForm}
                            disabled={isUpdating}
                        >
                            {update ? "Update" : "Submit"}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default RegistrantProfilePageForm;
