import React, { createContext } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import useMessage from "src/hooks/useMessage";
import { DataContextType } from "src/types/dataContextTypes";
import api from "src/utils/apiConfig";
import { getRefreshToken, setRefreshToken } from "../utils/jwtFunctions";
import { RequestEnum } from "../enums/RequestTypesEnum";
import NotificationPopup from "src/components/popup/NotifiicationPopup";

const DataContext = createContext<DataContextType>({} as DataContextType);

export const DataProvider = ({ children }: { children: React.ReactNode }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const { showError } = useMessage();

    const handleLogout = async () => {
        setRefreshToken("REM");
        auth?.logout();
    };

    const profileComplete = async (message: any) => {
        if (message === "Please complete your profile details") {
            navigate("/registrantProfile");
        } else {
            showError(message);
            await handleLogout();
        }
    };

    const handleRefreshSession = async (
        method: RequestEnum,
        error: any,
        url: string,
        data?: any,
    ) => {
        if (auth.user?.username) {
            if (
                error?.response?.data?.message !==
                "Please complete your profile details"
            ) {
                const refreshAuth = await getRefreshToken(auth.user?.username);
                if (refreshAuth) {
                    try {
                        let response = null;
                        switch (method) {
                            case RequestEnum.GET:
                                response = await api.get(url);
                                break;
                            case RequestEnum.POST:
                                response = await api.post(url, data);
                                break;
                            case RequestEnum.PATCH:
                                response = await api.patch(url, data);
                                break;
                            case RequestEnum.DELETE:
                                response = await api.delete(url);
                                break;
                        }
                        return response?.data ?? null;
                    } catch (e: any) {
                        if (e?.response?.status == 401) {
                            await profileComplete(
                                error?.response?.data?.message,
                            );
                        } else {
                            throw error;
                        }
                    }
                } else {
                    await handleLogout();
                }
            } else {
                await profileComplete(error?.response?.data?.message);
            }
        } else {
            await handleLogout();
        }
    };

    const fetchData = async (url: string) => {
        try {
            const response = await api.get(url, {});
            return response.data;
        } catch (error: any) {
            if (error?.response?.status === 401) {
                return await handleRefreshSession(RequestEnum.GET, error, url);
            }
            throw error;
        }
    };

    const postData = async (url: string, data: any) => {
        try {
            const response = await api.post(url, data);
            return response.data;
        } catch (error: any) {
            if (error?.response?.status === 401) {
                return await handleRefreshSession(
                    RequestEnum.POST,
                    error,
                    url,
                    data,
                );
            }
            throw error;
        }
    };

    const patchData = async (url: string, data: any) => {
        try {
            const response = await api.patch(url, data, {});
            return response.data;
        } catch (error: any) {
            if (error?.response?.status === 401) {
                return await handleRefreshSession(
                    RequestEnum.PATCH,
                    error,
                    url,
                    data,
                );
            } else {
                throw error;
            }
        }
    };
    const deleteData = async (url: string) => {
        try {
            const response = await api.delete(url, {});
            return response.data;
        } catch (error: any) {
            if (error?.response?.status === 401) {
                return await handleRefreshSession(
                    RequestEnum.DELETE,
                    error,
                    url,
                );
            } else {
                throw error;
            }
        }
    };

    return (
        <DataContext.Provider
            value={{ fetchData, postData, patchData, deleteData }}
        >
            <NotificationPopup />
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;
